import logo from './logo.PNG';
import device from './device.png';
import image from './image.svg';
import secure from './secure.svg';
import speed from './speed.svg';
import available from './available.svg';
import android from './android.svg';
import android_dark from './android-dark.svg';
import dot from './dot.svg';
import pocket from './pocket.svg';
import './App.css';

function App() {
  return (
    <div className="App">
    <div id ="anond1" style={{display:"flex", justifyContent:"flex-start", alignItems:"center"}}> 
      <img src={logo} className="logo" alt="logo" />
    <label className="brand-label">SendPeg </label> 

    </div>

    
      <div className="div1" >
          <div id="anond" style={{display:"flex",justifyContent:"center",alignItems:"baseline"}}>
            
              
              <div style={{display:"flex",justifyContent:"center",alignItems:"flex-start",flexGrow:"1",flexDirection:"column"}}>      
                <h1 id="headerH" style={{margin:0}}>Verify Payments Quickly</h1>
                <div><label className="c-label"> Make your business easy to manage by swiftly receiving confirmation for transfers</label></div>
              </div>

              <div style={{marginTop:"0rem",flexGrow:"1",display:"flex",justifyContent:"center", alignItems:"flex-end"}}>
                <div>
                  <img src={android} className="App-logo" alt="logo"   onClick={()=>{ window.open('https://play.google.com/store/apps/details?id=com.sendpeg','_blank') }} />
                </div>
              </div>
            
          </div>

           <div id="anond2">
              <img src={image} className="lady" alt="logo" />
           </div>
            
      </div>  
      <div className="info-div" style={{display:"flex", backgroundColor:"#003b67"}}>
        
        <div className="sec-div" style={{display:"flex",flexDirection:"column",alignItems:"flex-start",color:"#fff",textAlign:"start"}}>
          <h1 className="mini-header">Get the Peg</h1>
          <label className="desc-label" style={{color:"#8f9aa0"}}>The peg is a hardware device that can verify when a customer has paid you. Here's how it can transform your business</label>
          <div>
              <button type="button" className="want-button" onClick={()=>{ window.open('https://forms.gle/h33FHcCtDtK1BKkc8','_blank'); }}> I want one ( ₦6,000 )</button>
            </div>
        </div>

        <div style={{display:"flex",flexDirection:"column", flexGrow:"1"}}>
          
          <div className="sec-div" style={{display:"flex",flexDirection:"column",alignItems:"flex-start",color:"#f7e9d9", backgroundColor:"#044376"}}>
             <img src={secure} className="App-logo" alt="logo" />
             <h1 className="mini-header">Privacy</h1>
            <label className="desc-label">Enable Sales representatives to receive alerts only for specific transactions</label>
          </div>

          <div className="sec-div" style={{display:"flex",flexDirection:"column",alignItems:"flex-start",color:"#f7e9d9", backgroundColor:"#044376"}}>
            <img src={speed} className="App-logo" alt="logo" />
             <h1 className="mini-header">Speed</h1>
            <label className="desc-label">No more delay in waiting for SMS alerts. Keep it moving</label>
          </div>
        
        </div>

        <div style={{display:"flex",flexDirection:"column"}}>
          
          <div className="sec-div" style={{display:"flex",flexDirection:"column",alignItems:"flex-start",color:"#f7e9d9", backgroundColor:"#044376"}}>
            <img src={pocket} className="App-logo" alt="logo" />
            <h1 className="mini-header">Cheap</h1>
            <label className="desc-label">Eliminate costs and time wasted calling account manager to verify transactions</label>
          </div>

          <div className="sec-div" style={{display:"flex",flexDirection:"column",alignItems:"flex-start",color:"#f7e9d9", backgroundColor:"#044376"}}>
            <img src={dot} className="App-logo" alt="logo" />
            <h1 className="mini-header">Flexible</h1>
            <label className="desc-label">Easily receive payments anywhere with any bank account. </label>
          </div>
        
        </div>
      

      </div>
      
      <div className="sec-div" id="sec1-div" style={{display:"flex", flexDirection:"column",margin:"0rem", backgroundColor:"black"}}>
        <h1 className="mini-header" style={{color:"white"}}>Download the SendPeg App</h1>
          
            <div>
              <img src={android_dark} className="App-logo" alt="logo" onClick={()=>{ window.open('https://play.google.com/store/apps/details?id=com.sendpeg','_blank') }} />
            </div>

            
                
      </div>  

    </div>
  );
}

export default App;
